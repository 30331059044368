'use client'

import * as React from 'react'
import { Check, ChevronsUpDown, XIcon } from 'lucide-react'
import { cn } from '@/lib/utils'
import { Button, LoadingButton } from '@/components/ui/Button'
import { Command, CommandEmpty, CommandInput, CommandItem, CommandList, CommandLoading } from '@/components/ui/Command'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/Popover'
import { Skeleton } from './Skeleton'
import { Badge } from './Badge'
import { Option } from './utils'
import { Tooltip } from './Tooltip'

interface Props<Value extends string = string, Data = any> {
  options: Option<Value, Data>[]
  value: Option<Value, Data> | Option<Value, Data>[] | null
  defaultValue?: Option<Value, Data> | Option<Value, Data>[] | null
  optionPlaceholder?: string
  searchPlaceholder?: string
  disabled?: boolean
  classes?: {
    trigger?: string
    content?: string
  }
  shouldFilter?: boolean
  isLoading?: boolean
  isLoadingOptions?: boolean
  maxBadges?: number
  multiselect?: boolean
  hideFilter?: boolean
  clearable?: boolean
  modal?: boolean

  onInputChange?: (value: string) => void
  onSelect?: (option: Option<Value, Data> | Option<Value, Data>[] | null) => void // Supports both modes
  onOpenChange?: (open: boolean) => void
}

export function Combobox<Value extends string, Data>({
  options,
  value,
  defaultValue,
  optionPlaceholder = 'Select...',
  searchPlaceholder = 'Search...',
  disabled,
  multiselect,
  classes,
  shouldFilter,
  hideFilter = false,
  clearable = false,
  isLoading,
  isLoadingOptions,
  modal,
  onInputChange,
  onSelect,
  maxBadges = 2,
  onOpenChange,
}: Props<Value, Data>) {
  const [open, setOpen] = React.useState(false)
  const [_value, setValue] = React.useState(defaultValue ?? (multiselect ? [] : null))

  const selectedValue = value !== undefined ? value : _value
  const hasValue = multiselect ? Array.isArray(selectedValue) && selectedValue.length > 0 : selectedValue !== null

  const handleSelect = (selectedOption: Option<Value, Data> | null) => {
    if (multiselect) {
      const currentValues = Array.isArray(selectedValue) ? selectedValue : []

      if (selectedOption) {
        const isAlreadySelected = currentValues.some((v) => v.value === selectedOption.value)
        const newValues = isAlreadySelected
          ? currentValues.filter((v) => v.value !== selectedOption.value)
          : [...currentValues, selectedOption]

        setValue(newValues)
        onSelect?.(newValues)
      } else {
        setValue([])
        onSelect?.([])
      }
    } else {
      setValue(selectedOption)
      onSelect?.(selectedOption)
    }
  }

  const handleClearClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    handleSelect(null)
  }

  const handleOptionsSelect = (selectedOption: Option<Value, Data>) => {
    handleSelect(selectedOption)
    if (!multiselect) setOpen(false)
  }

  const renderBadges = () => {
    if (!multiselect || !Array.isArray(selectedValue)) return null

    const displayBadges = selectedValue.slice(0, maxBadges)
    const remainingCount = selectedValue.length - maxBadges

    return (
      <div className="flex flex-wrap gap-2 max-w-full overflow-hidden whitespace-nowrap">
        {displayBadges.map((v) => (
          <Badge
            key={v.value}
            variant="outline"
            className="text-ellipsis overflow-hidden whitespace-nowrap inline-block"
          >
            {v.label}
          </Badge>
        ))}
        {remainingCount > 0 && <Badge variant="outline">+{remainingCount} more</Badge>}
      </div>
    )
  }

  const _options = React.useMemo(() => {
    if (multiselect && Array.isArray(selectedValue)) {
      const selectedOptions = Array.isArray(selectedValue) ? selectedValue : []
      const selectedValues = selectedOptions.map((v) => v.value)

      // Remove selected options from the main list
      const remainingOptions = options.filter((o) => !selectedValues.includes(o.value))

      // Combine selected options with the remaining ones
      return [...selectedOptions, ...remainingOptions]
    } else if (!multiselect && !Array.isArray(selectedValue)) {
      // Keep the selected option at the top
      return selectedValue ? [selectedValue, ...options.filter((o) => o.value !== selectedValue.value)] : options
    }

    return options
  }, [selectedValue, options, multiselect])

  React.useEffect(
    function handleOpenChange() {
      onOpenChange?.(open)
    },
    [open],
  )

  return (
    <Tooltip
      content={
        disabled && Array.isArray(selectedValue) && maxBadges && selectedValue.length > maxBadges
          ? selectedValue.map((v) => (
              <React.Fragment key={v.value}>
                - {v.label}
                <br />
              </React.Fragment>
            ))
          : ''
      }
      side="bottom"
    >
      <div>
        <Popover modal={modal} open={open} onOpenChange={setOpen}>
          <PopoverTrigger asChild>
            <div
              className={cn('relative', {
                'cursor-pointer': !disabled,
                '!cursor-not-allowed': disabled,
                'opacity-50': disabled,
                'pointer-events-none': disabled,
              })}
            >
              {clearable && hasValue && !disabled && (
                <Button
                  variant="ghost"
                  className="p-0 opacity-50 h-fit hover:opacity-100 absolute right-1 top-3"
                  onClick={handleClearClick}
                >
                  <XIcon size={14} />
                </Button>
              )}

              <LoadingButton
                variant="outline"
                role="combobox"
                aria-expanded={open}
                className={cn('justify-start h-[unset] py-[7px]', classes?.trigger)}
                loading={isLoading}
                type="button"
              >
                <div className="flex gap-2 items-center justify-between flex-1 overflow-hidden whitespace-nowrap text-ellipsis">
                  {multiselect && Array.isArray(selectedValue) && selectedValue.length > 0 ? (
                    renderBadges()
                  ) : selectedValue && !Array.isArray(selectedValue) ? (
                    <span className="text-ellipsis overflow-hidden whitespace-nowrap font-normal">
                      {selectedValue.label}
                    </span>
                  ) : (
                    <span className="text-ellipsis overflow-hidden whitespace-nowrap font-normal text-slate-400">
                      {optionPlaceholder}
                    </span>
                  )}

                  <ChevronsUpDown className="h-4 w-4 shrink-0 opacity-50 self-start mt-[3px]" />
                </div>
              </LoadingButton>
            </div>
          </PopoverTrigger>
          <PopoverContent className={cn('p-0', classes?.content)}>
            <Command shouldFilter={shouldFilter}>
              {!hideFilter && (
                <CommandInput placeholder={searchPlaceholder} onInput={(e) => onInputChange?.(e.currentTarget.value)} />
              )}

              {!isLoadingOptions && (
                <CommandEmpty className="py-[22px] text-center text-sm">Nothing found</CommandEmpty>
              )}

              <CommandList>
                {isLoadingOptions ? (
                  <CommandLoading className="p-0">
                    <div className="flex flex-col gap-1 py-1 px-1">
                      <Skeleton className="h-[26px]" />
                      <Skeleton className="h-[26px]" />
                    </div>
                  </CommandLoading>
                ) : (
                  _options.map((o) => (
                    <CommandItem
                      key={o.value}
                      value={o.label}
                      onSelect={() => handleOptionsSelect(o)}
                      className="cursor-pointer relative text-start"
                    >
                      {o.startAdornment ? (
                        <div className="mr-2">{o.startAdornment}</div>
                      ) : (
                        <Check
                          className={cn('mr-2 min-h-4 min-w-4 max-h-4 max-w-4 opacity-0', {
                            'opacity-100': Array.isArray(selectedValue)
                              ? selectedValue.some((v) => v.value === o.value)
                              : o.value === selectedValue?.value,
                          })}
                        />
                      )}

                      {o.label}

                      {o.endAdornment && <div className="ml-auto">{o.endAdornment}</div>}
                    </CommandItem>
                  ))
                )}
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
      </div>
    </Tooltip>
  )
}
